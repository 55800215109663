@font-face {
    font-family: CorporationGames;
    src: url('/CorporationGames.woff') format('woff'),
        url('/CorporationGames.ttf') format('truetype');
}

body {
    background-color: #fbfbfb;
    color: var(--dark);
}

.btn,
.top-card {
    font-family: CorporationGames, Nunito, -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
}

.top-card {
    font-size: 1.75rem;
    width: 100%;
    text-align: center;
    margin-top: 8px;
}

.list-group-item {
    background-color: #fbfbfb;
}
